import { useEffect, useState } from "react";
import { generateUuid } from "../../../../data/data.utils";
import "./energy-class.css";

const types = {
	energy: {
		title: "Classe énergie",
		type: "Classe énergie",
		description:
			"La correspondance d'échelle dépend de la date de réalisation du DPE. Pour les DPE délivrés à compter du 1er juillet 2021, les valeurs E, F et G diffèrent pour les biens en zone climatique H1b, H1c et H2d et à une altitude supérieure à 800m. Veuillez prendre contact avec l'annonceur pour connaître ces éléments.",
	},
	ges: {
		title: "GES",
		type: "Emissions de Gaz à Effet de Serre (GES)",
		description:
			"La correspondance d'échelle dépend de la date de réalisation du DPE. Pour les DPE délivrés à compter du 1er juillet 2021, les valeurs E, F et G diffèrent pour les biens en zone climatique H1b, H1c et H2d et à une altitude supérieure à 800m. Veuillez prendre contact avec l'annonceur pour connaître ces éléments.",
	},
};

const energyClasses = ["a", "b", "c", "d", "e", "f", "g"];

const EnergyClass = ({ value, type, className }) => {
	// We need a uuid to make sure that the active class is added to the correct element, else this would add the active class to an EnergyClass with the same type
	const [uuid, setUuid] = useState(generateUuid());
	const [showDetails, setShowDetails] = useState(false);

	useEffect(() => {
		const activeClass = document.querySelector(
			`#${uuid} .energy-classes div.${value.toLowerCase()}`
		);
		activeClass?.classList?.add("active");
	}, [type, value, uuid]);

	return (
		energyClasses.find((ec) => ec === value?.toLowerCase()) && (
			<div id={uuid} className={`energy-class ${className} ${type}`}>
				<div className="energy-class__content__left__title energy-class-title">
					<h6>{types[type].title}</h6>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						class="bi bi-info-circle-fill"
						viewBox="0 0 16 16"
						onClick={() => setShowDetails((old) => !old)}
						className="energy-info-icon"
					>
						<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
					</svg>
				</div>
				<div className="energy-classes d-flex flex-row align-items-center pt-3 pb-3">
					<div className="a"></div>
					<div className="b"></div>
					<div className="c"></div>
					<div className="d"></div>
					<div className="e"></div>
					<div className="f"></div>
					<div className="g"></div>
				</div>
				{showDetails && (
					<div className="energy-class-details">
						<div className="popup">
							<span>
								<h4>{types[type].type}</h4>
								<div
									className="close"
									onClick={() =>
										setShowDetails((old) => !old)
									}
								>
									&times;
								</div>
							</span>
							<img
								src={
									type === "ges"
										? "/assets/img/energy-classes/elapida-ges-details.avif"
										: "/assets/img/energy-classes/elapida-energy-classes-details.avif"
								}
								alt={`descriptions de la classe ${types[type].type} `}
							/>
							<div className="content">
								{types[type].description}
							</div>
						</div>
					</div>
				)}
			</div>
		)
	);
};

export default EnergyClass;
