/* -----------Components---------------- */
import NotificationComponent from '../alert-component/component';
/* -----------Assets---------------- */
import _ from "lodash";
import React, { useState } from 'react';
import { env } from '../../environnement';
import { useDispatch, useSelector } from "react-redux";
import ReCaptchaV2 from 'react-google-recaptcha';
import { sendMailContactAPI } from '../../redux/contact/index.api';
import { setContactForm } from '../../redux/contact/index.slice';
/* -----------Selectors------------ */
import { contactFormSelector } from '../../redux/contact/index.selector';
/* -----------Style Sheet------------ */

const ContactSection = () => {
	/* -----------Selectors------------ */
	const form = useSelector(contactFormSelector);
	/* -----------State Hook------------ */
	/* -----------Vars------------ */
	const dispatch = useDispatch();
	const recaptchaRef = React.createRef();

	/* -----------Functions------------ */
	const onFormChange_handler = (name, e) => {
		let payload = _.cloneDeep(form);
		if (name === "token") {
			if (e) {
				dispatch(setContactForm({
					...payload,
					[name]: e
				}))
			} else {
				delete payload[name]
				dispatch(setContactForm({ ...payload }))
			}
		} else {
			let { target: { value } } = e;
			if (value) {
				dispatch(setContactForm({
					...payload,
					[name]: value
				}))
			} else {
				delete payload[name]
				dispatch(setContactForm({ ...payload }))
			}

		}
	}
	const ValidateEmail = (mail) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true
		}
		return false
	}
	const handle_submit = (e) => {
		e.preventDefault();
		if (form.contactName && form.contactMail && ValidateEmail(form.contactMail) && form.token) {
			let payload = _.cloneDeep(form);
			delete payload.token;
			dispatch(sendMailContactAPI(payload))

		} else {
			NotificationComponent(
				true,
				"error",
				"Echec de l'envoi du formulaire",
				form.contactName, form.contactMail, ValidateEmail(form.contactMail), form.token,
			)
		}
	}
	const onOk_handler = () => {
		window.alert("haa")
	}
	/* -----------render------------ */
	return (
		<div>
			<div className="contact-area pd-top-100 pd-bottom-65">
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<div className="contact-page-map">
								<iframe className="w-100" src="https://maps.google.com/maps?width=100%25&height=600&hl=fr&q=12%20Rue%20du%20Duc%20de%20Guise,%20Calais,%20France+(Valucian)&t=&z=16&ie=UTF8&iwloc=B&output=embed" style={{ border: 0 }} allowFullScreen />
							</div>
						</div>
						<div className="col-lg-4">
							<form className="contact-form-wrap contact-form-bg p-0">
								<h4>Nous contacter</h4>
								<div className="rld-single-input">
									<input type="text" placeholder="Nom et prénom"
										onChange={(e) => onFormChange_handler("contactName", e)}
									/>
								</div>
								<div className="rld-single-input">
									<input type="text" placeholder="Adresse mail"
										onChange={(e) => onFormChange_handler("contactMail", e)} />
								</div>
								<hr />
								<div className="rld-single-select">
									<select className="single-select"
											onChange={(e) => onFormChange_handler("contactJour", e)}>
										<option disabled selected value="" hidden>Je souhaite être appelé le...</option>
										<option value="lundi">lundi</option>
										<option value="mardi">mardi</option>
										<option value="mercredi">mercredi</option>
										<option value="jeudi">jeudi</option>
										<option value="vendredi">vendredi</option>
										<option value="peu importe">peu importe</option>
										<option value="Ne me rappelez pas">Ne me rappelez pas...</option>
									</select>
								</div>
								<div className="rld-single-select">
									<select className="single-select"
											onChange={(e) => onFormChange_handler("contactHoraire", e)}>
										<option disabled selected value="" hidden>Je préfère être appelé...</option>
										<option value="Entre 9H et 12H">Entre 9H et 12H</option>
										<option value="Entre 12H et 14 H">Entre 12H et 14 H</option>
										<option value="Entre 14H et 17H">Entre 14H et 17H</option>
										<option value="après 17H">après 17H</option>
										<option value="">Je vous ai dit de ne pas me rappeler :)</option>
									</select>
								</div>
								<div className="rld-single-input">
									<input type="text" placeholder="Téléphone (pratique pour être rappelé)"
										   onChange={(e) => onFormChange_handler("contactPhone", e)} />
								</div>
								<hr />
								<div className="rld-single-select">
									<select className="single-select"
										onChange={(e) => onFormChange_handler("contactSubject", e)}>
										<option disabled selected value="" hidden>Je suis...</option>
										<option value="Je suis vendeur">Je suis vendeur</option>
										<option value="Je suis acheteur">Je suis acheteur</option>
										<option value="Autre">Autre</option>
									</select>
								</div>

								<div className="rld-single-input">
									<textarea rows={10} placeholder="Message" defaultValue={""}
										onChange={(e) => onFormChange_handler("contactMessage", e)} />
								</div>
								<ReCaptchaV2
									ref={recaptchaRef}
									sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY.toString()}
									onChange={(e) => onFormChange_handler("token", e)}
								/>
								<div className="btn-wrap text-center">
									<button className="btn btn-yellow"
										onClick={handle_submit}>
										Envoyer</button>
								</div>
							</form>
						</div>
					</div>
					<div className="row pd-top-92">
						<div className="col-xl-4 col-sm-6">
							<div className="single-contact-info">
								<p><i className="fa fa-phone" />Nous appeler</p>
								<h5>03 21 36 19 31</h5>
							</div>
						</div>
						<div className="col-xl-4 col-sm-6">
							<div className="single-contact-info">
								<p><i className="fa fa-envelope" />Nous écrire</p>
								<h5>contact@valucian.fr</h5>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="single-contact-info">
								<p><i className="fa fa-map-marker" />Adresse</p>
								<h5>12 Rue du Duc de Guise</h5>
								<h5>62100 Calais</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactSection

